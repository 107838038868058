import { createApp } from 'vue';
import './plugins/assets';
import VueDatePicker from '@vuepic/vue-datepicker';
import { setupAppVersionNotification, setupDayjs, setupIconifyOffline, setupLoading, setupNProgress } from './plugins';
import { setupStore } from './store';
import { setupRouter } from './router';
import { setupI18n } from './locales';
import '@vuepic/vue-datepicker/dist/main.css';
import App from './App.vue';

async function setupApp() {
  setupLoading();

  setupNProgress();

  setupIconifyOffline();

  setupDayjs();

  const app = createApp(App);

  setupStore(app);

  await setupRouter(app);

  setupI18n(app);

  setupAppVersionNotification();

  app.component('VueDatePicker', VueDatePicker);

  app.mount('#app');
}

setupApp();
