/* eslint-disable */
/* prettier-ignore */
// Generated by elegant-router
// Read more: https://github.com/soybeanjs/elegant-router

import type { RouteComponent } from "vue-router";
import type { LastLevelRouteKey, RouteLayout } from "@elegant-router/types";

import BaseLayout from "@/layouts/base-layout/index.vue";
import BlankLayout from "@/layouts/blank-layout/index.vue";

export const layouts: Record<RouteLayout, RouteComponent | (() => Promise<RouteComponent>)> = {
  base: BaseLayout,
  blank: BlankLayout,
};

export const views: Record<LastLevelRouteKey, RouteComponent | (() => Promise<RouteComponent>)> = {
  403: () => import("@/views/_builtin/403/index.vue"),
  404: () => import("@/views/_builtin/404/index.vue"),
  500: () => import("@/views/_builtin/500/index.vue"),
  "iframe-page": () => import("@/views/_builtin/iframe-page/[url].vue"),
  login: () => import("@/views/_builtin/login/index.vue"),
  card_kinds: () => import("@/views/card/kinds/index.vue"),
  card_list: () => import("@/views/card/list/index.vue"),
  card_order: () => import("@/views/card/order/index.vue"),
  coupons_kinds: () => import("@/views/coupons/kinds/index.vue"),
  coupons_list: () => import("@/views/coupons/list/index.vue"),
  home: () => import("@/views/home/index.vue"),
  sys_merchant: () => import("@/views/sys/merchant/index.vue"),
  sys_profile: () => import("@/views/sys/profile/index.vue"),
  user_kyc: () => import("@/views/user/kyc/index.vue"),
  user_list: () => import("@/views/user/list/index.vue"),
  wallet_address: () => import("@/views/wallet/address/index.vue"),
  wallet_list: () => import("@/views/wallet/list/index.vue"),
  wallet_traderecharge: () => import("@/views/wallet/tradeRecharge/index.vue"),
  wallet_tradetransfer: () => import("@/views/wallet/tradeTransfer/index.vue"),
};
