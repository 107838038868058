/* eslint-disable */
/* prettier-ignore */
// Generated by elegant-router
// Read more: https://github.com/soybeanjs/elegant-router

import type { GeneratedRoute } from '@elegant-router/types';

export const generatedRoutes: GeneratedRoute[] = [
  {
    name: '403',
    path: '/403',
    component: 'layout.blank$view.403',
    meta: {
      title: '403',
      i18nKey: 'route.403',
      constant: true,
      hideInMenu: true
    }
  },
  {
    name: '404',
    path: '/404',
    component: 'layout.blank$view.404',
    meta: {
      title: '404',
      i18nKey: 'route.404',
      constant: true,
      hideInMenu: true
    }
  },
  {
    name: '500',
    path: '/500',
    component: 'layout.blank$view.500',
    meta: {
      title: '500',
      i18nKey: 'route.500',
      constant: true,
      hideInMenu: true
    }
  },
  {
    name: 'card',
    path: '/card',
    component: 'layout.base',
    meta: {
      title: 'card',
      i18nKey: 'route.card',
      order: 2
    },
    children: [
      {
        name: 'card_kinds',
        path: '/card/kinds',
        component: 'view.card_kinds',
        meta: {
          title: 'card_kinds',
          i18nKey: 'route.card_kinds'
        }
      },
      {
        name: 'card_list',
        path: '/card/list',
        component: 'view.card_list',
        meta: {
          title: 'card_list',
          i18nKey: 'route.card_list'
        }
      },
      {
        name: 'card_order',
        path: '/card/order',
        component: 'view.card_order',
        meta: {
          title: 'card_order',
          i18nKey: 'route.card_order'
        }
      }
    ]
  },
  {
    name: 'coupons',
    path: '/coupons',
    component: 'layout.base',
    meta: {
      title: 'coupons',
      i18nKey: 'route.coupons',
      order: 3
    },
    children: [
      {
        name: 'coupons_kinds',
        path: '/coupons/kinds',
        component: 'view.coupons_kinds',
        meta: {
          title: 'coupons_kinds',
          i18nKey: 'route.coupons_kinds'
        }
      },
      {
        name: 'coupons_list',
        path: '/coupons/list',
        component: 'view.coupons_list',
        meta: {
          title: 'coupons_list',
          i18nKey: 'route.coupons_list'
        }
      }
    ]
  },
  {
    name: 'home',
    path: '/home',
    component: 'layout.base$view.home',
    meta: {
      title: 'home',
      i18nKey: 'route.home',
      icon: 'mdi:monitor-dashboard',
      order: 1
    }
  },
  {
    name: 'iframe-page',
    path: '/iframe-page/:url',
    component: 'layout.base$view.iframe-page',
    props: true,
    meta: {
      title: 'iframe-page',
      i18nKey: 'route.iframe-page',
      constant: true,
      hideInMenu: true,
      keepAlive: true
    }
  },
  {
    name: 'login',
    path: '/login/:module(pwd-login|code-login|register|reset-pwd|bind-wechat)?',
    component: 'layout.blank$view.login',
    props: true,
    meta: {
      title: 'login',
      i18nKey: 'route.login',
      constant: true,
      hideInMenu: true
    }
  },
  {
    name: 'sys',
    path: '/sys',
    component: 'layout.base',
    meta: {
      title: 'sys',
      i18nKey: 'route.sys',
      order: 100
    },
    children: [
      {
        name: 'sys_merchant',
        path: '/sys/merchant',
        component: 'view.sys_merchant',
        meta: {
          title: 'sys_merchant',
          i18nKey: 'route.sys_merchant'
        }
      },
      {
        name: 'sys_profile',
        path: '/sys/profile',
        component: 'view.sys_profile',
        meta: {
          title: 'sys_profile',
          i18nKey: 'route.sys_profile'
        }
      }
    ]
  },
  {
    name: 'user',
    path: '/user',
    component: 'layout.base',
    meta: {
      title: 'user',
      i18nKey: 'route.user',
      order: 1
    },
    children: [
      {
        name: 'user_kyc',
        path: '/user/kyc',
        component: 'view.user_kyc',
        meta: {
          title: 'user_kyc',
          i18nKey: 'route.user_kyc',
          order: 2
        }
      },
      {
        name: 'user_list',
        path: '/user/list',
        component: 'view.user_list',
        meta: {
          title: 'user_list',
          i18nKey: 'route.user_list',
          order: 1
        }
      }
    ]
  },
  {
    name: 'wallet',
    path: '/wallet',
    component: 'layout.base',
    meta: {
      title: 'wallet',
      i18nKey: 'route.wallet',
      order: 2
    },
    children: [
      {
        name: 'wallet_address',
        path: '/wallet/address',
        component: 'view.wallet_address',
        meta: {
          title: 'wallet_address',
          i18nKey: 'route.wallet_address'
        }
      },
      {
        name: 'wallet_list',
        path: '/wallet/list',
        component: 'view.wallet_list',
        meta: {
          title: 'wallet_list',
          i18nKey: 'route.wallet_list'
        }
      },
      {
        name: 'wallet_traderecharge',
        path: '/wallet/traderecharge',
        component: 'view.wallet_traderecharge',
        meta: {
          title: 'wallet_traderecharge',
          i18nKey: 'route.wallet_traderecharge'
        }
      },
      {
        name: 'wallet_tradetransfer',
        path: '/wallet/tradetransfer',
        component: 'view.wallet_tradetransfer',
        meta: {
          title: 'wallet_tradetransfer',
          i18nKey: 'route.wallet_tradetransfer'
        }
      }
    ]
  }
];
