import { profile } from 'node:console';

const local: App.I18n.Schema = {
  system: {
    title: 'VitaPay管理系统',
    updateTitle: '系统版本更新通知',
    updateContent: '检测到系统有新版本发布，是否立即刷新页面？',
    updateConfirm: '立即刷新',
    updateCancel: '稍后再说'
  },
  common: {
    audit: '审核',
    action: '操作',
    add: '新增',
    addSuccess: '添加成功',
    backToHome: '返回首页',
    batchDelete: '批量删除',
    cancel: '取消',
    close: '关闭',
    check: '勾选',
    expandColumn: '展开列',
    columnSetting: '列设置',
    config: '配置',
    confirm: '确认',
    detail: '详细',
    delete: '删除',
    records: '记录',
    deleteSuccess: '删除成功',
    confirmDelete: '确认删除吗？',
    frozen: '冻结',
    frozenSuccess: '冻结成功',
    confirmFrozen: '确认冻结吗？',
    unfrozen: '解冻',
    unfrozenSuccess: '解冻成功',
    confirmUnfrozen: '确认解冻吗？',
    recovery: '恢复',
    confirmRecovery: '确认恢复吗？',
    edit: '编辑',
    warning: '警告',
    error: '错误',
    index: '序号',
    keywordSearch: '请输入关键词搜索',
    logout: '退出登录',
    logoutConfirm: '确认退出登录吗？',
    lookForward: '敬请期待',
    modify: '修改',
    modifySuccess: '修改成功',
    noData: '无数据',
    operate: '操作',
    pleaseCheckValue: '请检查输入的值是否合法',
    refresh: '刷新',
    reset: '重置',
    search: '搜索',
    status: '状态',
    switch: '切换',
    tip: '提示',
    trigger: '触发',
    update: '更新',
    updateSuccess: '更新成功',
    taskCommit: '任务已提交',
    updateTime: '更新时间',
    userCenter: '个人中心',
    yesOrNo: {
      yes: '是',
      no: '否'
    }
  },
  request: {
    logout: '请求失败后登出用户',
    logoutMsg: '用户状态失效，请重新登录',
    logoutWithModal: '请求失败后弹出模态框再登出用户',
    logoutWithModalMsg: '用户状态失效，请重新登录',
    refreshToken: '请求的token已过期，刷新token',
    tokenExpired: 'token已过期'
  },
  theme: {
    themeSchema: {
      title: '主题模式',
      light: '亮色模式',
      dark: '暗黑模式',
      auto: '跟随系统'
    },
    grayscale: '灰色模式',
    colourWeakness: '色弱模式',
    layoutMode: {
      title: '布局模式',
      vertical: '左侧菜单模式',
      'vertical-mix': '左侧菜单混合模式',
      horizontal: '顶部菜单模式',
      'horizontal-mix': '顶部菜单混合模式',
      reverseHorizontalMix: '一级菜单与子级菜单位置反转'
    },
    recommendColor: '应用推荐算法的颜色',
    recommendColorDesc: '推荐颜色的算法参照',
    themeColor: {
      title: '主题颜色',
      primary: '主色',
      info: '信息色',
      success: '成功色',
      warning: '警告色',
      error: '错误色',
      followPrimary: '跟随主色'
    },
    scrollMode: {
      title: '滚动模式',
      wrapper: '外层滚动',
      content: '主体滚动'
    },
    page: {
      animate: '页面切换动画',
      mode: {
        title: '页面切换动画类型',
        'fade-slide': '滑动',
        fade: '淡入淡出',
        'fade-bottom': '底部消退',
        'fade-scale': '缩放消退',
        'zoom-fade': '渐变',
        'zoom-out': '闪现',
        none: '无'
      }
    },
    fixedHeaderAndTab: '固定头部和标签栏',
    header: {
      height: '头部高度',
      breadcrumb: {
        visible: '显示面包屑',
        showIcon: '显示面包屑图标'
      }
    },
    tab: {
      visible: '显示标签栏',
      cache: '缓存标签页',
      height: '标签栏高度',
      mode: {
        title: '标签栏风格',
        chrome: '谷歌风格',
        button: '按钮风格'
      }
    },
    sider: {
      inverted: '深色侧边栏',
      width: '侧边栏宽度',
      collapsedWidth: '侧边栏折叠宽度',
      mixWidth: '混合布局侧边栏宽度',
      mixCollapsedWidth: '混合布局侧边栏折叠宽度',
      mixChildMenuWidth: '混合布局子菜单宽度'
    },
    footer: {
      visible: '显示底部',
      fixed: '固定底部',
      height: '底部高度',
      right: '底部局右'
    },
    watermark: {
      visible: '显示全屏水印',
      text: '水印文本'
    },
    themeDrawerTitle: '主题配置',
    pageFunTitle: '页面功能',
    configOperation: {
      copyConfig: '复制配置',
      copySuccessMsg: '复制成功，请替换 src/theme/settings.ts 中的变量 themeSettings',
      resetConfig: '重置配置',
      resetSuccessMsg: '重置成功'
    }
  },
  route: {
    login: '登录',
    403: '无权限',
    404: '页面不存在',
    500: '服务器错误',
    'iframe-page': '外链页面',
    home: '首页',
    user: '用户管理',
    card: '卡管理',
    user_list: '用户列表',
    user_kyc: 'KYC列表',
    card_list: '卡列表',
    card_kinds: '卡种类',
    card_order: '订单列表',
    coupons: '优惠券管理',
    coupons_kinds: '优惠券种类',
    coupons_list: '优惠券列表',
    wallet: '钱包管理',
    wallet_list: '钱包列表',
    wallet_address: '地址列表',
    wallet_traderecharge: '充值交易',
    wallet_tradetransfer: '转账交易',
    sys: '系统管理',
    sys_merchant: '商户信息',
    sys_profile: '系统信息'
  },
  page: {
    login: {
      common: {
        loginOrRegister: '登录 / 注册',
        userNamePlaceholder: '请输入用户名',
        phonePlaceholder: '请输入手机号',
        codePlaceholder: '请输入验证码',
        passwordPlaceholder: '请输入密码',
        confirmPasswordPlaceholder: '请再次输入密码',
        codeLogin: '验证码登录',
        confirm: '确定',
        back: '返回',
        validateSuccess: '验证成功',
        loginSuccess: '登录成功',
        welcomeBack: '欢迎回来，{userName} ！'
      },
      pwdLogin: {
        title: '密码登录',
        rememberMe: '记住我',
        forgetPassword: '忘记密码？',
        register: '注册账号',
        otherAccountLogin: '其他账号登录',
        otherLoginMode: '其他登录方式',
        superAdmin: '超级管理员',
        admin: '管理员',
        user: '普通用户'
      },
      codeLogin: {
        title: '验证码登录',
        getCode: '获取验证码',
        reGetCode: '{time}秒后重新获取',
        sendCodeSuccess: '验证码发送成功',
        imageCodePlaceholder: '请输入图片验证码'
      },
      register: {
        title: '注册账号',
        agreement: '我已经仔细阅读并接受',
        protocol: '《用户协议》',
        policy: '《隐私权政策》'
      },
      resetPwd: {
        title: '重置密码'
      },
      bindWeChat: {
        title: '绑定微信'
      }
    },
    home: {
      greeting: '早安，{userName}, 今天又是充满活力的一天!',
      weatherDesc: '今日多云转晴，20℃ - 25℃!',
      projectCount: '项目数',
      todo: '待办',
      message: '消息',
      downloadCount: '下载量',
      registerCount: '注册量',
      schedule: '作息安排',
      study: '学习',
      work: '工作',
      rest: '休息',
      entertainment: '娱乐',
      visitCount: '访问量',
      turnover: '成交额',
      dealCount: '成交量',
      projectNews: {
        title: '项目动态',
        moreNews: '更多动态',
        desc1: 'Soybean 在2021年5月28日创建了开源项目 soybean-admin!',
        desc2: 'Yanbowe 向 soybean-admin 提交了一个bug，多标签栏不会自适应。',
        desc3: 'Soybean 准备为 soybean-admin 的发布做充分的准备工作!',
        desc4: 'Soybean 正在忙于为soybean-admin写项目说明文档！',
        desc5: 'Soybean 刚才把工作台页面随便写了一些，凑合能看了！'
      },
      creativity: '创意'
    },
    sys: {
      hdwallet: {
        title: 'HD钱包',
        blockchainCode: '区块代码',
        currentPathIndex: '当前路径',
        mainAddress: '主钱包地址',
        mainAddressBalance: '主钱包余额',
        gatherAddress: '归集地址',
        minRechargeAmt: '最低充值金额',
        minConfirmNum: '最低确认数量'
      },
      profile: {
        title: '配置参数'
      },
      merchant: {
        ysInfo: '银盛信息',
        matewalkerInfo: 'MateWalker信息',
        matewalker: {
          uid: '商户ID',
          cuy: '货币代码',
          amt: '商户余额'
        },
        ys: {
          cpname: '公司名称',
          credlimit: '可用额度',
          credasign: '已用额度'
        }
      }
    },
    manage: {
      common: {
        status: {
          enable: '启用',
          disable: '禁用'
        }
      },
      user: {
        title: '用户列表',
        userName: '用户名',
        userGender: '性别',
        realName: '姓名',
        nickName: '昵称',
        userPhone: '手机号',
        userEmail: '邮箱',
        userStatus: '用户状态',
        userRole: '用户角色',
        inviteCode: '邀请码',
        regTime: '注册时间',
        form: {
          userName: '请输入用户名',
          userGender: '请选择性别',
          nickName: '请输入昵称',
          userPhone: '请输入手机号',
          userEmail: '请输入邮箱',
          userStatus: '请选择用户状态',
          userRole: '请选择用户角色'
        },
        addUser: '新增用户',
        editUser: '编辑用户',
        genderTitle: '性别(M男 F女)',
        gender: {
          male: '男',
          female: '女'
        }
      },
      kyc: {
        title: 'KYC列表',
        title_audit: 'Kyc审核',
        kycLevel: 'kyc级别',
        level_one: '一级',
        level_two: '二级',
        idType: '证件类型',
        idType_identify: '身份证',
        idType_other: '其他',
        idNumber: '证件号码',
        embname: '凸字姓名',
        birthDate: '出生日期',
        marStatus: '婚姻(S未婚 M已婚 D离婚 W丧偶 O其它)',
        addressType: '地址类型(B公司 F 户籍 H家庭 W房产)',
        address: '地址',
        postCode: '邮编',
        stmCode: '寄送账单(EM 电子邮件 IE 电子邮件 LE 信件电子邮件 LI 信函+电子邮件 LT 信件寄送)',
        applyTime: '申请时间',
        auditTime: '审核时间',
        finishTime: '完成时间',
        pictureId: '证件照',
        pictureSign: '签名照',
        refuseCause: '审核备注',
        genStatus: '构建状态',
        kycStatus: '审核状态',
        status_init: '申请中',
        status_pass: '已通过',
        status_unpass: '未通过',
        status_refuse: '已拒绝'
      },
      card: {
        addEntity: '新增实体卡',
        title: '卡列表',
        kinds_title: '卡种类',
        kinds_add: '添加卡种类',
        kinds_edit: '修改卡种类',
        cardType: '卡类型',
        kindsCode: '产品编码',
        openingFee: '开卡费',
        rechargeFeePercent: '充值手续费比率',
        minChargeAmt: '最低充值金额',
        type_virtual: '虚拟卡',
        type_physical: '实体卡',
        cardTitle: '卡标题',
        cardNumber: '卡号',
        cardDesc: '卡描述',
        supplierCode: '渠道代码',
        credLimit: '信用额度',
        avlimit: '可用额度',
        bal_amt: '卡余额',
        bal_limt: '限制余额',
        openDate: '开户日期',
        applyTime: '申请时间',
        finishTime: '激活时间',
        // 1待开卡 2待激活 8已激活
        status_init: '待开卡',
        status_active: '待激活',
        status_finish: '已激活',
        status_logoff: '已注销'
      },
      order: {
        // 1开卡 2充值 3消费
        orderType: '订单类型',
        type: {
          open: '开卡',
          recharge: '充值',
          consume: '消费'
        },
        orderNo: '订单编号',
        openingAmt: '开卡金额',
        chargeAmt: '充值金额',
        chargeAmtFee: '充值手续费',
        deductAmt: '扣减金额',
        orderAmt: '订单总金额',
        // 1已下单 2已处理 3已发货 4错误关闭
        orderStatus: '订单状态',
        status: {
          init: '已下单',
          process: '已处理',
          deliver: '已发货',
          error: '错误关闭'
        },
        reqStatus: '接口状态',
        rstatus: {
          init: '未请求',
          process: '已请求待返回',
          success: '请求成功',
          fail: '请求失败'
        },
        refundFlag: '退款状态',
        rFlag: {
          no: '无退款',
          party: '部分退款',
          all: '全部退款'
        },
        callRecord: '报文',
        refund: '退款',
        refundList: {
          title: ''
        },
        refundAmt: '退款金额',
        refundCause: '退款原因',
        isDelivery: '是否寄递',
        createTime: '创建时间',
        refundTime: '退款时间',
        reconnect: '重试',
        termination: '终止订单',
        message: {
          resultCode: '处理结果',
          content: '报文内容'
        }
      },
      wallet: {
        title: '钱包列表',
        balance: '余额',
        currency: '币种',
        tradeNo: '交易号',
        optCode: '操作代码',
        remark: '备注',
        beforeBalance: '改变前金额',
        changeAmt: '改动金额',
        walletId: '钱包ID',
        blockchainCode: '区块代码',
        blockchainAddress: '区块地址',
        balanceWei: '余额(WEI)',
        gatherStatus: '归集状态',
        gatherAmtWei: '归集金额(WEI)',
        noFeeStatus: '手续费',
        lastRechargeTime: '充值时间',
        lastCheckTime: '检查时间',
        lastGatherTime: '归集时间',
        gatherBtn: '开始归集',
        gatherConfirm: '只能启动一个归集进程，是否开始？',
        feeBtn: '转入手续费',
        feeTransferDesc: '请给主钱包放入足够的余额，满足子钱包归集时需要的手续费。充值完成后点击确认。',
        gather: {
          up: '归集中',
          down: '未归集'
        },
        nofee: {
          nor: '正常',
          lack: '缺少',
          handle: '处理中'
        },
        blockNum: '区块高度',
        tradeHash: '交易Hash',
        tradeAmtWei: '交易金额',
        tradeType: '交易类型',
        tytpe: {
          fee: '手续费',
          gather: '归集'
        },
        tradeStatus: '交易状态',
        tstatus: {
          notConfirm: '未确认',
          fail: '失败',
          notFound: '未找到',
          success: '成功'
        },
        enterActStatus: '入账状态',
        eastatus: {
          init: '未入账',
          cancel: '已取消',
          success: '已入账'
        }
      },
      coupons: {
        couponCode: '优惠券代码',
        couponType: '优惠券类型',
        // 1 现金抵扣 2 比率递减
        type: {
          cash: '现金抵扣',
          radio: '比率递减'
        },
        // 前置条件 1直接抵扣 2满xx多少减xxx
        condition: {
          deduction: '直接抵扣',
          fullReduct: '每满xx减xxx'
        },
        // 1未使用 2已使用 3已过期
        userStatus: {
          init: '未使用',
          used: '已使用',
          expired: '已过期'
        },
        sceneCode: '场景代码',
        cardIds: '卡种类ID',
        condAmt: '条件金额（>=订单金额）',
        deductAmt: '扣减金额',
        deductPercent: '扣减比率',
        expireBeginTime: '有效开始时间',
        expireEndTime: '有效结束时间',
        usageTime: '使用时间'
      }
    }
  },

  form: {
    required: '不能为空',
    userName: {
      required: '请输入用户名',
      invalid: '用户名格式不正确'
    },
    phone: {
      required: '请输入手机号',
      invalid: '手机号格式不正确'
    },
    pwd: {
      required: '请输入密码',
      invalid: '密码格式不正确，6-18位字符，包含字母、数字、下划线'
    },
    confirmPwd: {
      required: '请输入确认密码',
      invalid: '两次输入密码不一致'
    },
    code: {
      required: '请输入验证码',
      invalid: '验证码格式不正确'
    },
    email: {
      required: '请输入邮箱',
      invalid: '邮箱格式不正确'
    }
  },
  dropdown: {
    closeCurrent: '关闭',
    closeOther: '关闭其它',
    closeLeft: '关闭左侧',
    closeRight: '关闭右侧',
    closeAll: '关闭所有'
  },
  icon: {
    themeConfig: '主题配置',
    themeSchema: '主题模式',
    lang: '切换语言',
    fullscreen: '全屏',
    fullscreenExit: '退出全屏',
    reload: '刷新页面',
    collapse: '折叠菜单',
    expand: '展开菜单',
    pin: '固定',
    unpin: '取消固定'
  },
  datatable: {
    itemCount: '共 {total} 条'
  }
};

export default local;
